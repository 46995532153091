<template>
  <div class="panel panel-default">
    <div class="panel-heading">四川植烟土壤管理 > 编辑土壤数据</div>
    <div class="panel-body">
      <div class="table-responsive input-form">
        <p><b>基本信息</b></p>
        <div class="table-responsive">
          <table class="table table-bordered">
            <thead>
              <tr class="th">
                <th>市州</th>
                <th>区县</th>
                <th>乡镇</th>
                <th>村组</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <select v-model="city_state">
                    <option
                      v-for="item in sichuanLocationArr"
                      :key="item"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                </td>
                <td><input type="text" v-model="district_county" /></td>
                <td><input type="text" v-model="township" /></td>
                <td>
                  <input type="text" v-model="village" style="width: 200px" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <input
          type="submit"
          class="btn btn-default my-submit"
          value="提交"
          @click="handleSubmit()"
        />
      </div>

      <br />

      <div class="table-responsive input-form">
        <p><b>烟草采样点层次详细信息</b></p>
        <div class="table-responsive">
          <table class="table table-bordered">
            <thead>
              <tr class="th">
                <th class="text-center">操作</th>

                <th>样品编号</th>
                <th>土层厚度</th>
                <th>pH</th>
                <th>有机质</th>
                <th>全氮</th>
                <th>全磷</th>
                <th>全钾</th>
                <th>碱解氮</th>
                <th>有效磷</th>
                <th>速效钾</th>
                <th>缓效钾</th>
                <th>CEC</th>
                <th>水溶性盐总量</th>
                <th>交换性钙</th>
                <th>交换性镁</th>
                <th>有效铜</th>
                <th>有效锌</th>
                <th>有效铁</th>
                <th>有效锰</th>
                <th>有效硼</th>
                <th>有效钼</th>
                <th>有效硫</th>
                <th>氯离子</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in SichuanTobaccoDetailedMessageList"
                :key="item.detail_id"
              >
                <td class="text-center">
                  <a @click="removeRow(item.detail_id, index)">删除</a>
                </td>
                <td><input type="text" v-model="item.sample_id" /></td>
                <td><input type="text" v-model="item.soil_thickness" /></td>
                <td><input type="text" v-model="item.pH" /></td>
                <td><input type="text" v-model="item.organic_matter" /></td>
                <td><input type="text" v-model="item.total_nitrogen" /></td>
                <td><input type="text" v-model="item.total_phosphorus" /></td>
                <td><input type="text" v-model="item.total_kalium" /></td>
                <td>
                  <input
                    type="text"
                    v-model="item.alkali_hydrolyzable_nitrogen"
                  />
                </td>
                <td><input type="text" v-model="item.available_phosphor" /></td>
                <td>
                  <input type="text" v-model="item.rapidly_available_kalium" />
                </td>
                <td>
                  <input type="text" v-model="item.slowly_available_kalium" />
                </td>
                <td><input type="text" v-model="item.cec" /></td>
                <td><input type="text" v-model="item.water_soluble_salt" /></td>
                <td>
                  <input type="text" v-model="item.exchangeable_calcium" />
                </td>
                <td>
                  <input type="text" v-model="item.exchangeable_magnesium" />
                </td>
                <td><input type="text" v-model="item.available_copper" /></td>
                <td><input type="text" v-model="item.available_zinc" /></td>
                <td><input type="text" v-model="item.available_iron" /></td>
                <td>
                  <input type="text" v-model="item.available_manganese" />
                </td>
                <td><input type="text" v-model="item.available_boron" /></td>
                <td>
                  <input type="text" v-model="item.available_molybdenum" />
                </td>
                <td><input type="text" v-model="item.available_sulphur" /></td>
                <td><input type="text" v-model="item.chloridion" /></td>
              </tr>
            </tbody>
          </table>
        </div>
        <input
          type="submit"
          class="btn btn-default my-submit"
          value="提交"
          @click="handleSichuanTobaccoDetailedMessageSubmit()"
        /><span v-html="'\u00a0\u00a0\u00a0'"></span>
        <input
          type="submit"
          class="btn btn-default my-submit"
          value="增加层次"
          @click="addRow()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import globalData from "@/global.js";
import axios from "axios";
import qs from "qs";

export default {
  data() {
    return {
      sichuanLocationArr: globalData.sichuanLocationArr,
      // 表单校验
      errors: [],
      // 基本信息
      id: "",
      city_state: "",
      district_county: "",
      township: "",
      village: "",
      // 层次信息
      SichuanTobaccoDetailedMessageList: [],
    };
  },
  mounted() {
    // 加载从上一个页面传入的基本信息
    const item = JSON.parse(this.$route.query.item);
    // console.log(item);
    Object.keys(item).forEach((key) => {
      //   console.log(key, this[key], item[key])
      this[key] = item[key];
    });

    // 请求桑园采样点层次信息
    // TODO
    axios
      .get(`./data_tobacco/getSichuanTobaccoDetailedData?id=${this.id}`)
      .then((res) => {
        this.SichuanTobaccoDetailedMessageList = res.data.data;
      });
  },
  methods: {
    handleSubmit() {
      const { id, city_state, district_county, township, village } = this;

      let baseData = {
        id,
        city_state,
        district_county,
        township,
        village,
      };

      // 表单校验
      // this.errors = [];

      axios
        .post(
          `./data_tobacco/updateSichuanTobaccoBasicData`,
          qs.stringify(baseData)
        )
        .then((res) => {
          if (res.data.code != 200) {
            this.$message.error("操作失败, 请检查服务器");
            return;
          }
          // 更新数据
          this.$store.dispatch("getSichuanListAction");
          this.$message({
            message: "更新土壤数据成功",
            type: "success",
          });
        });
    },
    handleSichuanTobaccoDetailedMessageSubmit() {
      if (this.SichuanTobaccoDetailedMessageList.length == 0) return;

      let promiseArr = [];

      for (let i = 0; i < this.SichuanTobaccoDetailedMessageList.length; i++) {
        const element = this.SichuanTobaccoDetailedMessageList[i];
        promiseArr.push(
          new Promise((resolve, reject) => {
            axios
              .post(
                `/data_tobacco/updateSichuanTobaccoDetailedMessage`,
                qs.stringify(element)
              )
              .then((res) => {
                // console.log(res)
                if (res.data.code != 200) reject();
                resolve();
              });
          })
        );
      }

      Promise.all([promiseArr])
        .then(() => {
          this.$message({
            message: "更新土壤数据成功",
            type: "success",
          });
        })
        .catch(() => {
          this.$message.error("操作失败, 请检查服务器");
        });
    },
    removeRow(detail_id, index) {
      console.log(detail_id, index);

      this.$confirm("此操作将永久删除该条目, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          axios
            .delete("/data_tobacco/delSichuanTobaccoDetailedData", {
              params: { detail_id },
            })
            .then((res) => {
              console.log(res.data);
              if (res.data.code != 200) {
                this.$message.error("操作失败, 请检查服务器");
                return;
              }

              this.SichuanTobaccoDetailedMessageList.splice(index, 1);
              this.$message({
                message: "删除成功",
                type: "success",
              });
            });
        })
        .catch(() => {
          //   this.$message.info("已取消删除");
        });
    },
    addRow() {
      new Promise((resolve, reject) => {
        axios
          .post(
            `./data_tobacco/addSichuanTobaccoDetailedData`,
            qs.stringify({
              id: this.id,
            })
          )
          .then((res) => {
            if (res.data.code != 200) reject();
            resolve(res.data.data.detail_id);
          });
      })
        .then((detail_id) => {
          console.log(detail_id);
          this.SichuanTobaccoDetailedMessageList.push({
            detail_id,
            sample_id: "",
            soil_thickness: "",
            pH: "",
            organic_matter: "",
            total_nitrogen: "",
            total_phosphorus: "",
            total_kalium: "",
            alkali_hydrolyzable_nitrogen: "",
            available_phosphor: "",
            rapidly_available_kalium: "",
            slowly_available_kalium: "",
            cec: "",
            water_soluble_salt: "",
            exchangeable_calcium: "",
            exchangeable_magnesium: "",
            available_copper: "",
            available_zinc: "",
            available_iron: "",
            available_manganese: "",
            available_boron: "",
            available_molybdenum: "",
            available_sulphur: "",
            chloridion: "",
          });
          this.$message({
            message: "新增土壤数据成功",
            type: "success",
          });
        })
        .catch(() => {
          this.$message.error("操作失败, 请检查服务器");
        });
    },
  },
};
</script>

<style scoped>
.table {
  margin-bottom: 0px;
}
table:nth-child(1) {
  width: 35%;
}
.my-submit {
  margin-top: 7px;
}

table select,
table input {
  width: 100px;
}
select {
  padding: 1px 2px;
  height: 24px;
}
</style>